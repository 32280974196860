import React, { useEffect, useRef, useState } from "react"

import Img from 'gatsby-image'
import { Link } from 'gatsby';
import { Keyboard, Navigation, Pagination, Controller } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"

import { useLocalPath, useUrl } from '../../../hooks';
import { SwiperArrow } from "../../../svgs/swiper-arrow"
import { RichText } from "../../Layout/RichText/RichText"
import * as styles from './section-featured-carousel.module.scss';
import * as featuredStyles from './section-feature.module.scss';

import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/controller"

const SectionFeaturedCarousel = ({ data, pageSlug }) => {

    // Check if data.featuredItems exists and is not null
    if (!data.featuredItems) {
        return null; // or handle this case as per your requirement
    }

    // Check if any of the featuredItems have a type different than ContentfulBackgroundImageSection
    const hasNonBackgroundImageItems = data.featuredItems.some(
        item => item.__typename !== "ContentfulBackgroundImageSection"
    );

    // If any items have a different type than ContentfulBackgroundImageSection, return null
    if (hasNonBackgroundImageItems) {
        return null;
    }

    const {
        title: sectionTitle,
        backgroundColor
    } = data

    return (
        <div className={`${featuredStyles.sectionProduct} ${styles.featuredCarouselSection}`}>
            <div className={` ${backgroundColor ? featuredStyles.featured_container_green : featuredStyles.featured_container}`}>
                {sectionTitle && <h2 className={`yellow_border spacer__01 ${featuredStyles.featuredProductTitle} ${styles.featuredCarouselSection__title}`}>{sectionTitle}</h2>}
                <FeaturedCarousel data={data} />

            </div>
        </div>
    )
}

export default SectionFeaturedCarousel

// Controlled sliders component with image on left, description and navigation as column on right
const FeaturedCarousel = ({ data }) => {
    const [imageSwiper, setImageSwiper] = useState(null);
    const [contentSwiper, setContentSwiper] = useState(null);
    const imageSwiperRef = useRef(null);
    const contentSwiperRef = useRef(null);

    // Use the actual data from props
    const { featuredItems: carouselItems } = data;

    useEffect(() => {
        if (imageSwiperRef.current && imageSwiperRef.current.swiper) {
            const swiper = imageSwiperRef.current.swiper;
            setImageSwiper(swiper);

            // Destroy Swiper if there's only one item
            if (carouselItems.length === 1) {
                swiper.destroy(true, true);
            }
        }
        if (contentSwiperRef.current && contentSwiperRef.current.swiper) {
            const swiper = contentSwiperRef.current.swiper;
            setContentSwiper(swiper);

            // Destroy Swiper if there's only one item
            if (carouselItems.length === 1) {
                swiper.destroy(true, true);
            }
        }

        // Cleanup function for component unmount
        return () => {
            if (imageSwiperRef.current && imageSwiperRef.current.swiper) {
                imageSwiperRef.current.swiper.destroy(true, true);
            }
            if (contentSwiperRef.current && contentSwiperRef.current.swiper) {
                contentSwiperRef.current.swiper.destroy(true, true);
            }
        };
    }, [carouselItems.length]);

    const goPrev = () => {
        if (imageSwiper) {
            imageSwiper.slidePrev();
        }
    };

    const goNext = () => {
        if (imageSwiper) {
            imageSwiper.slideNext();
        }
    };

    const imageSwiperOptions = {
        spaceBetween: 32,
        slidesPerView: 1,
        loop: carouselItems?.length > 1,
        grabCursor: true,
        modules: [Controller, Keyboard],
        controller: { control: contentSwiper },
        watchSlidesProgress: true,
    };

    const contentSwiperOptions = {
        spaceBetween: 32,
        slidesPerView: 1,
        loop: carouselItems?.length > 1,
        modules: [Controller, Keyboard],
        controller: { control: imageSwiper },
        allowTouchMove: false,
        watchSlidesProgress: true,
        autoHeight: true,
    };

    // If there are no featured items, don't render the component
    if (!carouselItems || carouselItems.length === 0) {
        return null;
    }

    return (

        <div className={styles.featuredCarouselSection__slider}>
            <div className={styles.featuredCarouselSection__slide}>
                <div className={`${styles.featuredCarouselSection__swiperWrapper} ${styles.featuredCarouselSection__imageWrapper}`}>
                    <Swiper {...imageSwiperOptions} ref={imageSwiperRef}>
                        {carouselItems.map((carouselItem, index) => {
                            const { slug, imageSection } = carouselItem
                            return (
                                <SwiperSlide key={`img-${index}`}>
                                    <div className={styles.featuredCarouselSection__slideImage}>
                                        <Link to={useLocalPath(`/${slug}`)}>
                                            <Img fluid={imageSection?.fluid} alt={imageSection?.title} />
                                        </Link>
                                    </div>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>

                <>
                    <div className={`${styles.featuredCarouselSection__swiperWrapper} ${styles.featuredCarouselSection__contentWrapper}`}>
                        <Swiper {...contentSwiperOptions} ref={contentSwiperRef}>
                            {carouselItems.map((carouselItem, index) => {
                                const { description, slug, buttonUrl } = carouselItem
                                return (
                                    <SwiperSlide key={`desc-${index}`}>
                                        <div className={styles.featuredCarouselSection__slideContent}>
                                            <Link to={useLocalPath(`/${slug}`)}>
                                                <RichText content={description} />
                                            </Link>
                                            {buttonUrl && <Link className={styles.featuredCarouselSection__cta} to={useLocalPath(`/${slug}`)}>
                                                <button>{buttonUrl}</button>
                                            </Link>}
                                        </div>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>

                        {carouselItems.length > 1 && (
                            <div className={styles.featuredCarouselSection__pagination}>
                                <button
                                    className={`${styles.featuredCarouselSection__navButton} ${styles.featuredCarouselSection__navButtonPrev}`}
                                    onClick={goPrev}
                                    dangerouslySetInnerHTML={{ __html: SwiperArrow }}
                                />
                                <button
                                    className={`${styles.featuredCarouselSection__navButton} ${styles.featuredCarouselSection__navButtonNext}`}
                                    onClick={goNext}
                                    dangerouslySetInnerHTML={{ __html: SwiperArrow }}
                                />
                            </div>
                        )}
                    </div>
                </>
            </div>
        </div>
    );
};