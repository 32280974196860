// extracted by mini-css-extract-plugin
export var featuredCarouselSection__contentWrapper = "section-featured-carousel-module--featured-carousel-section__content-wrapper--68c19";
export var featuredCarouselSection__cta = "section-featured-carousel-module--featured-carousel-section__cta--b96a3";
export var featuredCarouselSection__imageWrapper = "section-featured-carousel-module--featured-carousel-section__image-wrapper--059f1";
export var featuredCarouselSection__navButton = "section-featured-carousel-module--featured-carousel-section__navButton--70103";
export var featuredCarouselSection__navButtonPrev = "section-featured-carousel-module--featured-carousel-section__navButton-prev--5b30a";
export var featuredCarouselSection__pagination = "section-featured-carousel-module--featured-carousel-section__pagination--743d0";
export var featuredCarouselSection__slide = "section-featured-carousel-module--featured-carousel-section__slide--fb75b";
export var featuredCarouselSection__slideContent = "section-featured-carousel-module--featured-carousel-section__slide-content--db33d";
export var featuredCarouselSection__slideImage = "section-featured-carousel-module--featured-carousel-section__slide-image--189fc";
export var featuredCarouselSection__slider = "section-featured-carousel-module--featured-carousel-section__slider--66b76";
export var featuredCarouselSection__swiperWrapper = "section-featured-carousel-module--featured-carousel-section__swiper-wrapper--6cc79";
export var featuredCarouselSection__title = "section-featured-carousel-module--featured-carousel-section__title--68b92";
export var richTextContent = "section-featured-carousel-module--rich-text-content--7cf7f";