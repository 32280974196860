import React, { useState, useEffect, useRef } from "react"
import Img from "gatsby-image"
import * as styles from "./hero-carousel.module.scss"
import { Link } from "gatsby"
import { useLocale, useLocalPath } from "../../../hooks"

import { useWindowSize } from 'react-use'
import Helmet from 'react-helmet'

import { Autoplay, Controller, Keyboard } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { SwiperArrow } from "../../../svgs/swiper-arrow"

import "swiper/css"
import "swiper/css/controller"

const SectionHeroCarousel = ({ data }) => {

    // Check if data.featuredItems exists and is not null
    if (!data.heroCarouselItems) {
        return null; // or handle this case as per your requirement
    }

    const { width: windowWidth } = useWindowSize()
    const [interaction, setInteraction] = useState(false)
    const events = ['scroll', 'mousemove', 'touchstart'];
    const initScripts = () => {
        setInteraction(true)

        events.forEach(event => {
            window.removeEventListener(event, initScripts)
        })
    }
    if (typeof window !== 'undefined') {
        events.forEach(event => {
            window.addEventListener(event, initScripts)
        })
    }

    return (
        <>
            <HeroCarousel data={data} />
        </>
    )
}
export default SectionHeroCarousel

const HeroCarousel = ({ data }) => {
    const [imageSwiper, setImageSwiper] = useState(null)
    const [ctaSwiper, setCtaSwiper] = useState(null)
    const imageSwiperRef = useRef(null);
    const ctaSwiperRef = useRef(null);

    const navigationRef = useRef(null);

    const { heroCarouselItems: carouselItems } = data;

    // set css variable based on navigation width
    // used to center the CTA
    useEffect(() => {
        const updateNavigationWidth = () => {
            if (navigationRef.current && window.innerWidth >= 1024) {
                const width = navigationRef.current.offsetWidth;
                document.documentElement.style.setProperty('--navigation-width', `${width}px`);
            }
        };

        // Initial measurement
        updateNavigationWidth();

        // Add resize listener
        window.addEventListener('resize', updateNavigationWidth);

        // Cleanup
        return () => {
            window.removeEventListener('resize', updateNavigationWidth);
        };
    }, []);

    useEffect(() => {
        if (imageSwiperRef.current && imageSwiperRef.current.swiper) {
            const swiper = imageSwiperRef.current.swiper;
            setImageSwiper(swiper);

            // Destroy Swiper if there's only one item
            if (carouselItems.length === 1) {
                swiper.destroy(true, true);
            }
        }
        if (ctaSwiperRef.current && ctaSwiperRef.current.swiper) {
            const swiper = ctaSwiperRef.current.swiper;
            setCtaSwiper(swiper);

            // Destroy Swiper if there's only one item
            if (carouselItems.length === 1) {
                swiper.destroy(true, true);
            }
        }

        // Cleanup function for component unmount
        return () => {
            if (imageSwiperRef.current && imageSwiperRef.current.swiper) {
                imageSwiperRef.current.swiper.destroy(true, true);
            }
            if (ctaSwiperRef.current && ctaSwiperRef.current.swiper) {
                ctaSwiperRef.current.swiper.destroy(true, true);
            }
        };
    }, [carouselItems.length]);

    const goPrev = () => {
        if (imageSwiper) {
            imageSwiper.slidePrev();
        }
    };

    const goNext = () => {
        if (imageSwiper) {
            imageSwiper.slideNext();
        }
    };

    const imageSwiperoptions = {
        spaceBetween: 0,
        slidesPerView: 1,
        loop: carouselItems?.length > 1,
        grabCursor: true,
        modules: [Autoplay, Controller, Keyboard],
        controller: { control: ctaSwiper },
        watchSlidesProgress: true,
        autoplay: {
            delay: 4500,
            pauseOnMouseEnter: true,
            disableOnInteraction: false
        }
    }

    const ctaSwiperOptions = {
        spaceBetween: 0,
        slidesPerView: 1,
        loop: carouselItems?.length > 1,
        modules: [Controller, Keyboard],
        controller: { control: imageSwiper },
        allowTouchMove: false,
        watchSlidesProgress: true,
        autoHeight: true,
    }

    // If there are no featured items, don't render the component
    if (!carouselItems || carouselItems.length === 0) {
        return null;
    }

    return (
        <div className={styles.heroCarouselSection}>
            {data.backgroundImageMobile &&
                <Helmet>
                    <link rel="preload" as="image" media="(min-width: 400.1px) and (max-width: 767px)" href={`${data.backgroundImageMobile.fluid.srcSet.split(/\s*,\s*/).slice(-1).join().split(" ")[0]}`} />
                    <link rel="preload" as="image" media="(max-width: 400px)" href={`${data.backgroundImageMobile.fluid.srcSet.split(/\s*,\s*/).slice(-2).join().split(" ")[0]}`} />
                </Helmet>
            }
            <div className={`${styles.heroCarouselSection__swiperWrapper} ${styles.heroCarouselSection__swiperHero}`}>
                <Swiper {...imageSwiperoptions} ref={imageSwiperRef}>
                    {carouselItems.map((carouselItem, index) => {
                        return (
                            <SwiperSlide key={`slide-${index}`}>
                                <HeroCarouselItem data={carouselItem} index={`item-${index}`} />
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
            
            <div className={`${styles.heroCarouselSection__swiperWrapper} ${styles.heroCarouselSection__swiperCta}`}>
                <Swiper {...ctaSwiperOptions} ref={ctaSwiperRef}>
                    {carouselItems.map((carouselItem, index) => {
                        const { cta } = carouselItem;
                        return (
                            <SwiperSlide key={`cta-${index}`}>
                                {cta && (
                                    <div className={styles.heroCarouselSection__slideContent}>
                                        <Link target="_blank" to={`${cta.slug}`} className="btn-primary">
                                            {cta.title}
                                        </Link>
                                    </div>
                                )}
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                {carouselItems.length > 1 && (
                    <div className={styles.heroCarouselSection__navigation} ref={navigationRef}>
                        <button
                            className={`${styles.heroCarouselSection__navButton} ${styles.heroCarouselSection__navButtonPrev}`}
                            onClick={goPrev}
                            dangerouslySetInnerHTML={{ __html: SwiperArrow }}
                        />
                        <button
                            className={`${styles.heroCarouselSection__navButton} ${styles.heroCarouselSection__navButtonNext}`}
                            onClick={goNext}
                            dangerouslySetInnerHTML={{ __html: SwiperArrow }}
                        />
                    </div>
                )}
            </div>

        </div>
    )
}

const HeroCarouselItem = ({ data, index }) => {

    const { name: title, cta, backgroundImage, backgroundImageMobile } = data;
    const locale = useLocale()

    return (
        <>
            {
                (locale !== "tr-TR") ? (
                    <div>
                        <div className={styles.heroCarouselSection__container}>
                            {backgroundImageMobile &&
                                <div className={`${styles.heroCarouselSection__image} ${styles.heroCarouselSection__imageMobile}`}>
                                    <div aria-hidden="true" style={{ width: '100%', paddingBottom: '142.364%' }}></div>
                                    <picture>
                                        <source
                                            media="(min-width: 400.1px) and (max-width: 767px)"
                                            srcSet={`${backgroundImageMobile.fluid.srcSet.split(/\s*,\s*/).slice(-1).join().split(" ")[0]}`}
                                        />
                                        <source
                                            media="(max-width: 400px)"
                                            srcSet={`${backgroundImageMobile.fluid.srcSet.split(/\s*,\s*/).slice(-2).join().split(" ")[0]}`}
                                        />
                                        <img
                                            src={`${backgroundImageMobile.fluid.src}`}
                                            loading={index === 0 ? "eager" : "lazy"}
                                            alt={backgroundImageMobile?.title ? backgroundImageMobile?.title : "Hero Image"}
                                        />
                                    </picture>
                                </div>
                            }
                            {backgroundImage &&
                                <Img
                                    fluid={backgroundImage.fluid}
                                    className={`${styles.heroCarouselSection__image} ${styles.heroCarouselSection__imageDesktop}`}
                                    alt={backgroundImage?.title ? backgroundImage?.title : "Hero Image"}
                                    loading={index === 0 ? "eager" : "lazy"}
                                    fadeIn={false}
                                />
                            }
                            <div className={styles.heroCarouselSection__title}>
                                <h1>{title}</h1>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <Link to={useLocalPath(`/${cta.slug}`)}>
                            <div className={styles.heroCarouselSection__container}>
                                {backgroundImageMobile &&
                                    <Img
                                        fluid={backgroundImageMobile.fluid}
                                        className={`${styles.heroCarouselSection__image} ${styles.heroCarouselSection__imageMobile}`}
                                        alt={backgroundImageMobile?.title ? backgroundImageMobile?.title : "Hero Image"}
                                        loading={index === 0 ? "eager" : "lazy"}
                                        fadeIn={false}
                                    />
                                }
                                {backgroundImage &&
                                    <Img
                                        fluid={backgroundImage.fluid}
                                        className={`${styles.heroCarouselSection__image} ${styles.heroCarouselSection__imageDesktop}`}
                                        alt={backgroundImage?.title ? backgroundImage?.title : "Hero Image"}
                                        loading={index === 0 ? "eager" : "lazy"}
                                        fadeIn={false}
                                    />
                                }
                                <div className={styles.heroCarouselSection_title}>
                                    <h1>{title}</h1>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
            }
        </>
    )
}