import React, {useEffect, useRef} from "react"
import { graphql } from "gatsby"
import FeaturedVideo from "../components/Shared/FeaturedVideoSection/FeaturedVideo"
import Hero from "../components/Shared/Home/HeroSection/Hero"
import SectionHeroCarousel from "../components/Shared/HeroCarousel/HeroCarousel"
import ProductGrid from "../components/Shared/ProductGrid/ProductGrid"
import PurposeVideo from "../components/Shared/PurposeVideo/PurposeVideo"
import { Slider } from "../components/Shared/Slider/Slider"
import { SectionFeatured } from "../components/Shared/SectionFeatured/SectionFeatured"
import SectionFeaturedCarousel from "../components/Shared/SectionFeatured/SectionFeaturedCarousel"
import { useLocale } from "../hooks"
import Schema from "../components/schema"
import Seo from "../components/seoGlobal"
import Articles from "../components/Shared/Generic/ArticlesSection/Articles"
import SectionHeadingDescription from "../components/sections/section-heading-description/sectionHeadingDescription"
import SectionAlternateGridSE from "../ArticlePage/SectionAlternateGrid2/SectionAlternateGridSE"
import SliderImages from "../components/sections/SliderSection/SliderImages"
import { getPathname } from "../utils/functions"
import loadable from "@loadable/component"
import SectionAlternateGrid from "../ArticlePage/SectionAlternateGrid/SectionAlternateGrid"
const ArticleContentMedia = loadable(
  () => import("../ArticlePage/ArticleContentMedia/ArticleContentMedia")
)
const Home = ({
  location,
  pageContext,
  data: {
    contentfulPageHome: { slug, seoTitle, sections, seoDescription, googleSiteVerificationId, bvUgcScript },
    allContentfulPageHome: { nodes: allLinks },
  },
}) => {
  const locale = useLocale()
  const trLink = {"slug": "/", "node_locale": "tr-TR"};
 
  const updatedLinks = [...allLinks, trLink];

  // BV UGC carousel asked to remove on 01/07/24--------------------------------------------
  // const useCuralateScript = () => {
  //   const handleLoadRef = useRef();
    
  //   useEffect(() => {
  //     const embedCuralateScript = () => {
  //       const CRL8_SITENAME = 'lipton-hrz117';
  //       const baseUrl = `https://edge.curalate.com/sites/${CRL8_SITENAME}/site/latest/`;
  //       const scriptUrl = `${baseUrl}site.min.js`;
        
  //       // Add preconnect for faster DNS resolution
  //       const preconnectLink = document.createElement('link');
  //       preconnectLink.rel = 'preconnect';
  //       preconnectLink.href = 'https://edge.curalate.com';
  //       document.head.appendChild(preconnectLink);
        
  //       // Load script
  //       const script = document.createElement('script');
  //       script.src = scriptUrl;
  //       script.defer = true; // Execute scripts in order
  //       script.onerror = () => console.error('Failed to load the Curalate script.');
  //       document.head.appendChild(script);
  //     };
  
  //     handleLoadRef.current = () => {
  //       embedCuralateScript();
  //       window.removeEventListener('load', handleLoadRef.current);
  //     };
  
  //     if (document.readyState === 'complete') {
  //       // Document already loaded, embed the script
  //       embedCuralateScript();
  //     } else {
  //       window.addEventListener('load', handleLoadRef.current);
  //     }
  
  //     // Cleanup function to remove event listener
  //     return () => {
  //       window.removeEventListener('load', handleLoadRef.current);
  //     };
  //   }, []);
  // };
  
  // useCuralateScript();

  console.log(sections)
  
  return (
    <div>
      <Seo
        lang={locale}
        title={seoTitle}
        description={seoDescription?.seoDescription}
        slick={false}
        hrefLangs={updatedLinks}
        verificationId={googleSiteVerificationId}
      />
      <Schema slug={getPathname(pageContext.node_locale, slug)} />
      {sections &&
        sections.map((section: any, index: number) => {
          switch (section.__typename) {
            case "ContentfulComponentHero":
              switch (section.type) {
                case "mainhero":
                  return (
                    <Hero
                      key={index}
                      heroSectionData={section}
                      showVideo={
                        !!(section.backgroundVideo
                          ? section.backgroundVideo.file.url
                          : section && section.youtubeBackgroundVideo)
                      }
                      index={index}
                    />
                  )
                case "pagehero":
                  return (
                    <FeaturedVideo key={index} featuredVideoData={section} />
                  )
                default:
                  return
              }
            case "ContentfulComponentHeroCarousel":
              return (
                <div key={index}>
                  <SectionHeroCarousel data={section} />
                </div>
              )  
            case "ContentfulComponentFeatured":
              return (
                <div key={index}>
                  <SectionFeatured data={section} />
                </div>
              )
            case "ContentfulComponentFeaturedCarousel":
              return (
                <div key={index}>
                  <SectionFeaturedCarousel data={section} />
                </div>
              )
            case "ContentfulComponentRelatedProductsArticles":
              return <Slider data={section} key={index} />
            case "ContentfulComponentYoutubeMedia":
              return (
                <PurposeVideo
                  key={index}
                  backgroundImage={section.bannerImage}
                  backgroundVideo={section.youtubeVideo}
                  description={section.heading}
                  title={section.desc}
                  home={true}
                  buttonDetails={section.buttonLink}
                />
              )
            case "ContentfulComponentAlternateGrid2":
              return (
                <SectionAlternateGridSE
                  position={section.positionContent}
                  description={section.description}
                  featuredImg={section.featuredImg}
                  featuredImgMobile={section.featuredImgMobile ? section.featuredImgMobile : null}
                  key={index}
                  type={section.imageSize ? section.imageSize : "Default"}
                  buttonText={section.buttonText}
                  buttonTextLink={section.buttonTextLink}
                />
              )
            case "ContentfulComponentAlternateGrid":
              return (
                <SectionAlternateGrid
                  position={section.positionContent}
                  sectionContent={section.sectionContent}
                  key={index}
                  sectionImage={section.sectionImage}
                  title={section.title}
                  key={index}
                  type={section.imageSize ? section.imageSize : "Default"}
                  buttonText={section.buttonText}
                  buttonSlug={section.buttonSlug}
                />
              )
            case "ContentfulComponentSliderSection":
              return (
                <div>
                  <SliderImages data={section} />
                </div>
              )
 
            case "ContentfulComponentFeaturedItems":
              switch (section.type) {
                case "componentGridItems":
                  return <Articles articles={section} key={index} />
              }
            case "ContentfulComponentContentMedia":
              return <ArticleContentMedia
                heading={section.heading}
                image={section.mediaImage}
                key={index}
              />
 
            case "ContentfulComponentHeadingDescription":
              return <SectionHeadingDescription section={section} key={index} />
          }
        })}
        {/* {bvUgcScript && <div data-crl8-container-id="homepage"></div>} */}
    </div>
  )
}
 
export const HomeQuery = graphql`
  query homePage($id: String!, $contentful_id: String!) {
    contentfulPageHome(id: { eq: $id }) {
      name
      node_locale
      bvUgcScript
      slug
      seoTitle
      seoDescription {
        seoDescription
      }
      googleSiteVerificationId
      seoImage {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      title
      sections {
        ... on ContentfulComponentFeaturedCarousel {
          __typename
          title
          name
          featuredItems {
            ... on ContentfulBackgroundImageSection {
              __typename
              slug
              buttonUrl
              description {
                raw
              }
              imageSection {
                title
                fluid(maxWidth: 1200, quality: 30) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        ... on ContentfulComponentFeatured {
          __typename
          title
          backgroundColor
          featuredItem {
            ... on ContentfulBackgroundImageSection {
              __typename
              slug
              buttonUrl
              description {
                raw
              }
              imageSection {
                title
                fluid(maxWidth: 1200, quality: 30) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
            ... on ContentfulPageProducts {
              __typename
              thumbnailDescription {
                thumbnailDescription
              }
              slug
              title
              thumbnailImage {
                title
                fluid( quality: 50) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              cta{
                ... on ContentfulComponentLink{
                  title
                }
              }
            }
            ... on ContentfulPageRecipe {
              __typename
              title
              slug
              heroImage {
                title
                fluid(quality: 80) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              subTitle
            }
          }
        }
        ... on ContentfulComponentRelatedProductsArticles {
          __typename
          dynamicBullets
          articleTriangleAlign
          navigation
          title
          type
          centeredAlign
          articles {
            slug
            articlePageIcon {
              title
              fluid(quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            thumbnailCategoryTitle
            title
            thumbnailTitle
            thumbnailImage {
              title
              fluid(quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          products {
            title
            slug
            thumbnailImage {
              title
              fluid(quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
          recipes {
            title
            slug
            heroImage {
              title
              fluid(quality: 90) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            subTitle
            recipeIcon {
              title
              fluid {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
        ... on ContentfulComponentAlternateGrid2 {
          __typename
          name
          description {
            raw
          }
          featuredImg {
            fluid (maxWidth: 1200 quality: 30){
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          featuredImgMobile {
            fluid (maxWidth: 1200 quality: 30){
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          buttonText
          buttonTextLink
        }
        ... on ContentfulComponentAlternateGrid {
          __typename
          name
          title
          positionContent
          sectionImage {
              title
              fluid(maxWidth: 1200 quality:30) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
              }
          }
          sectionContent {
              raw
          }
          imageSize
          buttonText
          buttonSlug
      }
        ... on ContentfulComponentYoutubeMedia {
          __typename
          heading
          desc: description
          youtubeVideo
          bannerImage {
            title
            fluid(quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
         
        }
        ... on ContentfulComponentSliderSection {
          name
          __typename
          name
          type
          navigation
          dynamicBullets
          sliderimages {
            fluid(quality: 50 maxWidth: 2300) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          sliderImagesmobile {
            fluid(quality: 50 maxWidth: 1200) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          heading
        }
     
        ... on ContentfulComponentHero {
          title
          __typename
          id
          name
          description {
            description
          }
          cta{
            title
            slug
          }
          backgroundImageMobile {
            title
            fluid(quality: 60) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          backgroundImage {
            title
            fluid(maxWidth:2000, quality: 60){
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          backgroundVideo {
            file {
              url
            }
          }
          youtubeBackgroundVideo
          type
        }
        ... on ContentfulComponentHeroCarousel {
          __typename
          title
          name
          heroCarouselItems {
            name
            backgroundImage {
              title
              fluid(maxWidth:2000, quality: 60){
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }            
            backgroundImageMobile {
              title
              fluid(quality: 60) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
            cta {
              title
              slug
            }              
          }
        }
        ... on ContentfulComponentFeaturedItems {
          title
          __typename
          subtitle
          type
          icon {
            title
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          backgroundImage {
            title
            fluid(quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          sectionThumbnail {
            __typename
            ... on ContentfulPageArticle {
              title
              __typename
              thumbnailTitle
              thumbnailCategoryTitle
              slug
              articlePageIcon {
                title
                fluid(quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              thumbnailImage {
                title
                fluid(quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              thumbnailDescription {
                thumbnailDescription
              }
              thumbnailCategoryTitle
            }
            ... on ContentfulPageProducts {
              thumbnailDescription {
                thumbnailDescription
              }
              __typename
              slug
              title
              thumbnailTitle
              productImg: thumbnailImage {
                title
                fluid(quality: 90) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
          }
        }
        ... on ContentfulComponentHeadingDescription {
          __typename
          id
          heading
          slug
          description {
            raw
          }
          buttonText
          simpleDescription2 {
            simpleDescription2
          }
        }
      }
    }
    allContentfulPageHome(
      filter: {
        contentful_id: { eq: $contentful_id }
        id: { ne: $id }
        slug: { ne: null }
      }
    ) {
      nodes {
        slug
        node_locale
      }
    }
  }
`
 
export default Home
