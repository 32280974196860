// extracted by mini-css-extract-plugin
export var heroCarouselSection = "hero-carousel-module--hero-carousel-section--de368";
export var heroCarouselSection__container = "hero-carousel-module--hero-carousel-section__container--bb5eb";
export var heroCarouselSection__image = "hero-carousel-module--hero-carousel-section__image--9e1fb";
export var heroCarouselSection__imageDesktop = "hero-carousel-module--hero-carousel-section__image--desktop--2e365";
export var heroCarouselSection__imageMobile = "hero-carousel-module--hero-carousel-section__image--mobile--2251b";
export var heroCarouselSection__navButton = "hero-carousel-module--hero-carousel-section__nav-button--4d4ba";
export var heroCarouselSection__navButtonPrev = "hero-carousel-module--hero-carousel-section__nav-button-prev--1fe86";
export var heroCarouselSection__navigation = "hero-carousel-module--hero-carousel-section__navigation--1e287";
export var heroCarouselSection__slideContent = "hero-carousel-module--hero-carousel-section__slide-content--2773f";
export var heroCarouselSection__swiperCta = "hero-carousel-module--hero-carousel-section__swiper-cta--a8bcd";
export var heroCarouselSection__swiperWrapper = "hero-carousel-module--hero-carousel-section__swiper-wrapper--36932";
export var heroCarouselSection__title = "hero-carousel-module--hero-carousel-section__title--f31e8";